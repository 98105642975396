// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

  $('.js-replace-img').each(function() {

    var src = $(this).find("img").attr("src");
    $(this).css("background-image","url("+src+")");
  });
};

jQuery(function() {
  if($(".js-replace-img")) {
    replaceSrc();
  }
});

// HIDE HEADER ON SCROLL DOWN
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('.main-header').outerHeight();

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('.main-header').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('.main-header').removeClass('nav-up').addClass('nav-down');
        }
    }

    lastScrollTop = st;

}

// Responsive menu
jQuery('#btnMobile').click(function() {
  jQuery(this).toggleClass('cross');
  jQuery('.main-nav-wrapper').toggleClass('open');
  jQuery('body').toggleClass('overflow');
});

// Responsive menu secondary
jQuery('#sub-nav-open').click(function() {
  jQuery(this).toggleClass('cross');
  jQuery('aside.category nav').toggleClass('open');
  jQuery('body').toggleClass('overflow');
});

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

     $('.js-replace-img').each(function() {

       var src = $(this).find("img").attr("src");
       $(this).css("background-image","url("+src+")");
     });
    };

    jQuery(function() {
     if($(".js-replace-img")) {
       replaceSrc();
     }
    });
