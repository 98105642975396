$.fn.fit = function (opts){

        var defaut = {

            method:'fill',
            offsetWidth:0,
            offsetHeight:0,
            fixedTo:'center'

        };

        var options         = $.extend({},defaut,opts),
            $dirtyElm       = null,
            instance        = this
            ;

        this.update = function (){

            instance.each (function (index,elm){

                $dirtyElm = $(elm);
                if (!$dirtyElm.is('img'))return;

                if ($dirtyElm[0].complete){

                    layoutFn($dirtyElm);

                }else {

                    $dirtyElm.on('load',function (){

                        layoutFn($(this));

                    });

                }

            });

            return this;

        };

        function layoutFn (elm){

            var parentW = elm.parent()[0].getBoundingClientRect().width,
                parentH = elm.parent()[0].getBoundingClientRect().height,
                transformOffsetW = elm.parent()[0].getBoundingClientRect().width - elm.parent().outerWidth(),
                transformOffsetH = elm.parent()[0].getBoundingClientRect().height - elm.parent().outerHeight(),
                mediaW = elm[0].naturalWidth,
                mediaH = elm[0].naturalHeight,
                sw = parentW/mediaW,
                sh = parentH/mediaH,
                s = 1,
                offsetW = 0,
                offsetH = 0;

            if (options.method == 'fill'){

                s = Math.max (sw,sh);

            }else {

                s = Math.min (sw,sh);

            }

            offsetW = ((parentW-(mediaW*s))-transformOffsetW-options.offsetWidth)/2;
            offsetH = ((parentH-(mediaH*s))-transformOffsetH-options.offsetHeight)/2;

            if (elm.hasClass('fix-top')){

                offsetH = 0;

            }
            elm.css({

                marginLeft:offsetW+'px',
                marginTop:offsetH+'px',
                width:mediaW*s+options.offsetWidth+'px',
                height:mediaH*s+options.offsetHeight+'px',
                display:'block'

            });

            elm.addClass('loaded');
            elm.addClass('cover-item');
        }

        if ($.fn.hasOwnProperty('smartresize')){

            $(window).smartresize(instance.update);

        }else {

            $(window).resize(instance.update);

        }

        return this.update();

    };



    